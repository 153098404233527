import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`What is it about?`}</h1>
    <p>{`Together with a co-worker, I made my first serious (>1000 EUR) investment in the cryptocurrency space in July 2019.
I have always been interest in the space and started following it more closely in the run-up to the bull market 2017.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      